import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"

const EmbedTextBlock = ({ block }) => {
  return (
    <BlockWrapper block={block} showHeadline="false">
      <div className="flex flex-row flex-wrap -mx-grid">
        <div className="w-full  lg:w-1/2 px-grid">
          {typeof window !== "undefined" && window.storyblok?.inEditor && (
            <div
              className="py-2 mb-8 rounded px-grid"
              style={{ backgroundColor: "#09b3af" }}
            >
              <p className="font-medium prose text-white">
                <h3 style={{ color: "#ffffff" }}>Hinweis:</h3>
                <b>Embed-Block:</b> Eingebetteten Inhalte, welche Scripte
                verwenden, können unter Umständen in der <i>Vorschau</i> nicht
                korrekt angezeigt werden.
              </p>
            </div>
          )}
          <div
            className={`embed embed-${block.ratio}`}
            dangerouslySetInnerHTML={{ __html: block.embed_code }}
          ></div>
        </div>
        <div className="w-full  lg:w-1/2 px-grid">
          <HeadlineEnhanced
            kicker={block.kicker}
            headline={block.headline}
            headlineLevel={block.headline_level}
            subheadline={block.subheadline}
            className="pt-8 lg:pt-0"
          />
          <Richtext text={block.text} className="pt-8" />
        </div>
      </div>
    </BlockWrapper>
  )
}

export default EmbedTextBlock
